
.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}
.custom-modal-wide .modal-dialog {
  max-width: 90%; /* Adjust the percentage as needed */
  width: auto !important;
}
.bootstrap-table .react-bootstrap-table {
  background-color: inherit; /* Set background color to inherit from parent */
}
.custom-table {
  color: #212529; /* Set text color */
  background-color: #fff; /* Set table background color */
}
.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
  padding-left: 0;
}

@media all and (min-width: 992px) {
  .sidebar .nav > li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
  }
}

.card.card-upgrade .card-category {
  max-width: 530px;
  margin: 0 auto;
}

.offline-doc .page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #ffffff;
  position: relative;
}
.offline-doc .page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.offline-doc .page-header .content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}
.offline-doc .page-header footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.offline-doc .page-header .container {
  height: 100%;
  z-index: 1;
}
.offline-doc .page-header .category,
.offline-doc .page-header .description {
  color: rgba(255, 255, 255, 0.8);
}
.offline-doc .page-header.page-header-small {
  min-height: 60vh;
  max-height: 440px;
}
.offline-doc .page-header.page-header-mini {
  min-height: 40vh;
  max-height: 340px;
}
.offline-doc .page-header .title {
  margin-bottom: 15px;
}
.offline-doc .page-header .title + h4 {
  margin-top: 10px;
}
.offline-doc .page-header:after,
.offline-doc .page-header:before {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}
.offline-doc .page-header:before {
  background-color: rgba(0, 0, 0, 0.8);
}
.child-docs-modal-wide .modal-dialog {
  max-width: 90%; /* Adjust the percentage as needed */
  width: auto !important;
}

.child-docs-table-header {
  width: 100%;
  margin-bottom: 1rem;
  color: #525f7f;
  background-color: transparent;
}

.child-doc-modal-table-wrapper {
  overflow: hidden;
  overflow-x: auto;
  background-color: #32325d;
}
.child-doc-modal-body {
  background-color: #525f7f;
}

.child-doc-modal {
  background-color: #1e1e2f;
}

.child-doc-modal-title {
  color: #fff;
}

.child-doc-non-scrollable-container {
  overflow: hidden;
  position: relative;
}

.fund-documents-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* This will space out the elements; remove if not needed */
}

.fund-documents-title {
  margin: 0; /* Remove default margin to ensure proper alignment */
}

.fund-documents-download-icon {
  font-size: 8pt;
  margin-left: 8px; /* Adjust spacing as needed */
  cursor: pointer;  /* Indicate that the icon is clickable */
}